<template>
  <div id="landing_0524">
    <section class="header img-container img-container-darken">
      <div class="container">
        <nav>
          <router-link class="nav-container" to="/">
            <img
              src="/svgs/logo_4.svg"
              alt="Logo NLC"
              class="logo_2 logo-nlc"
            />
          </router-link>

          <a href="mailto:info@nlcollege.es">
            <h5>{{ $lang("contactus") }}</h5>
          </a>
        </nav>
        <div class="row pt-md-4 py-lg-6">
          <div class="col-12 col-lg-6 mb-4 mb-lg-0">
            <h1 class="text-center text-lg-left">
              You are only one step away from starting the best experience of
              your life in Barcelona and Madrid!
            </h1>
          </div>
          <div class="col-12 col-lg-5 offset-lg-1">
            <h3 class="text-center mb-4">Chat with us!</h3>

            <form id="form" @submit.prevent="sendForm">
              <input
                type="text"
                placeholder="Full name*"
                v-model="form.name"
                required
              />
              <Select
                v-model="form.nationality"
                label="Nationality*"
                :options="nationalities"
              />
              <input
                type="text"
                placeholder="Email address*"
                v-model="form.email"
                required
              />
              <input
                type="text"
                placeholder="Whatsapp Number*"
                v-model="form.phone"
                required
              />
              <p class="small">* Mandatory fields</p>

              <div class="text-center">
                <button class="btn btn-secondary form-button" type="submit">
                  GO!
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-secondary text-dark">
      <div class="container">
        <div class="row gap-y-4 justify-content-center">
          <div class="col-12">
            <h2 class="mb-2 fw-600 text-center">Why choose NL College</h2>
          </div>
          <div class="col-6 col-md-4 col-lg">
            <div class="icon-container">
              <img src="/imgs/landing_0524/icon-1.png" alt="" />
              <h5>Native Spanish teachers</h5>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg">
            <div class="icon-container">
              <img src="/imgs/landing_0524/icon-2.png" alt="" />
              <h5>Weekly and fun activities</h5>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg">
            <div class="icon-container">
              <img src="/imgs/landing_0524/icon-3.png" alt="" />
              <h5>Accredited by the Cervantes Institute</h5>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg">
            <div class="icon-container">
              <img src="/imgs/landing_0524/icon-4.png" alt="" />
              <h5>Prime Locations in Barcelona and Madrid</h5>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg">
            <div class="icon-container">
              <img src="/imgs/landing_0524/icon-5.png" alt="" />
              <h5>+50 Nationalities studying with us</h5>
            </div>
          </div>
          <div class="col-12">
            <div class="text-center pt-4">
              <button @click="goToForm" class="btn btn-primary">
                I WANT TO LEARN SPANISH
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-white">
      <div class="container">
        <div class="row gap-y-3 mb-6">
          <div class="col-md-4">
            <div
              class="img-container img-container-rounded img-container-square"
              style="background-image: url('/imgs/landing_0524/photo_01.png')"
            />
          </div>
          <div class="col-md-4">
            <div
              class="img-container img-container-rounded img-container-square"
              style="background-image: url('/imgs/landing_0524/photo_02.png')"
            />
          </div>
          <div class="col-md-4">
            <div
              class="img-container img-container-rounded img-container-square"
              style="background-image: url('/imgs/landing_0524/photo_03.png')"
            />
          </div>
        </div>

        <div class="text-center">
          <h5 class="mb-5 fw-600 d-none d-md-block">
            With NL College, you'll be able to combine the best of both worlds
          </h5>
          <h3 class="mb-5 fw-600 d-md-none">
            With NL College, you'll be able to combine the best of both worlds
          </h3>

          <button @click="goToForm" class="btn btn-secondary">
            I WANT TO LEARN SPANISH
          </button>
        </div>
      </div>
    </section>

    <section
      class="text-white img-container img-container-darken"
      style="background-image: url('/imgs/landing_0524/background.png')"
    >
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <i class="isologo mb-3"></i>
            <h4 class="fw-600 mb-6 d-none d-md-block">
              What our students say about us
            </h4>
            <h3 class="fw-600 mb-6 d-md-none">
              What our students say about us
            </h3>
          </div>
          <div class="col-md-4 mb-5">
            <div class="testimonial">
              <div class="testimonial-header">
                <h5 class="name">Stefanie H</h5>
                <div class="stars">
                  <i class="fa fa-star" />
                  <i class="fa fa-star" />
                  <i class="fa fa-star" />
                  <i class="fa fa-star" />
                  <i class="fa fa-star" />
                </div>
              </div>
              <div class="testimonial-body">
                <p class="small">
                  I'm really happy to be studying Spanish at this school in
                  Barcelona. Carla, my advisor, informed me very well about
                  everything, and it was great to have her as my first point of
                  contact! The classes are structured and clear, and the teacher
                  adapts to the pace of the class and explains everything well.
                  I definitely recommend this school!
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-5">
            <div class="testimonial">
              <div class="testimonial-header">
                <h5 class="name">Jan E</h5>
                <div class="stars">
                  <i class="fa fa-star" />
                  <i class="fa fa-star" />
                  <i class="fa fa-star" />
                  <i class="fa fa-star" />
                  <i class="fa fa-star" />
                </div>
              </div>
              <div class="testimonial-body">
                <p class="small">
                  If you are serious about learning Spanish and meeting amazing
                  people, don't look any further. I tried learning Spanish in
                  another academy and on Babel but nothing works like NL
                  college. And the service definitely surpasses the price. Also
                  special appreciation for my advisor Carla Oyarce which was
                  extremely resourceful and welcoming pre course and along the
                  whole journey. She goes above and beyond to provide extra care
                  for all learners.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-5">
            <div class="testimonial">
              <div class="testimonial-header">
                <h5 class="name">Blendi B</h5>
                <div class="stars">
                  <i class="fa fa-star" />
                  <i class="fa fa-star" />
                  <i class="fa fa-star" />
                  <i class="fa fa-star" />
                  <i class="fa fa-star" />
                </div>
              </div>
              <div class="testimonial-body">
                <p class="small">
                  I’ve been attending this school for 2 months now, and it's
                  great! Very friendly staff! The advisor Carla helped me out so
                  much by answering all my questions and on boarding me to the
                  school. The teachers know what they're doing, and always
                  available to help you learn. I highly recommend it!!
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 text-center">
            <button @click="goToForm" class="btn btn-secondary">
              I WANT TO LEARN SPANISH
            </button>
          </div>
        </div>
      </div>
    </section>

    <section
      class="text-white img-container img-container-darken text-center pre-footer"
      style="background-image: url('/imgs/landing_0524/bottom_picture.png')"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-10 col-lg-9">
            <p class="h2 mb-5">Get ready for the best year of your life!</p>
            <p class="h5 mb-6">
              Learn Spanish with NL College while experiencing the beauties of
              Barcelona and Madrid
            </p>

            <button @click="goToForm" class="btn btn-secondary">
              I WANT TO LEARN SPANISH
            </button>
          </div>
        </div>
      </div>
    </section>

    <footer>
      <div class="container">
        <div class="row align-items-center">
          <div
            class="col-md-4 d-flex justify-content-center justify-content-md-start mb-4 mb-md-0 order-3 order-md-0"
          >
            <img src="/svgs/logo.svg" alt="Logo NLC" class="logo-nlc" />
          </div>
          <div class="col-md-4 d-flex flex-column gap-2 align-items-center">
            <a href="http://nlcollege.es" target="_blank"> nlcollege.es </a>
            <a href="mailto:info@nlcollege.es" target="_blank">
              info@nlcollege.es
            </a>

            <div class="socials">
              <a
                href="https://www.instagram.com/nlcollege.spain/"
                target="_blank"
                class="btn btn-white btn-rounded btn-sm"
              >
                <i class="fab fa-instagram"></i>
              </a>
              <a
                href="https://ne-np.facebook.com/nlcollege/"
                target="_blank"
                class="btn btn-white btn-rounded btn-sm"
              >
                <i class="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://www.tiktok.com/@nlcollege.spain"
                target="_blank"
                class="btn btn-white btn-rounded btn-sm"
              >
                <i class="fab fa-tiktok"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/nlcollege-spain/mycompany/"
                target="_blank"
                class="btn btn-white btn-rounded btn-sm"
              >
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          <div
            class="col-md-4 d-flex justify-content-md-end justify-content-center mt-4 mt-md-0"
          >
            <p>© 2024</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import countries_en from "@/assets/json/countries-en.json";
import Select from "@/components/Forms/Select.vue";
import axios from "axios";

export default {
  components: {
    Select,
  },
  data: () => ({
    form: {
      phone: null,
      email: null,
      name: null,
      nationality: null,
    },
    countries_en,
  }),
  computed: {
    nationalities() {
      return this.countries_en.map((x) => ({
        value: x.nationality,
        label: x.nationality,
      }));
    },
  },
  methods: {
    goToForm() {
      document.querySelector(".header").scrollIntoView();
    },
    sendForm() {
      axios
        .get("https://api.pumpmylead.com/integrations/informs/v1/webhook", {
          params: {
            "your-phone": this.form.phone,
            "your-email": this.form.email,
            "your-name": this.form.name,
            "your-nationality": this.form.nationality,
            token: "54akfz2302jvegkl119552d46942dzq4b",
          },
        })
        .then(() => {
          this.$router.push({ name: "thanks" });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#landing_0524 {
  /** TEXTS */
  font-size: 21px;
  --fs-sm: clamp(0.65rem, 0.59vi + 0.51rem, 0.98rem);
  --fs-base: clamp(0.81rem, 0.89vi + 0.6rem, 1.31rem);
  --fs-md: clamp(1.02rem, 1.3vi + 0.71rem, 1.75rem);
  --fs-lg: clamp(1.27rem, 1.89vi + 0.82rem, 2.33rem);
  --fs-xl: clamp(1.59rem, 2.71vi + 0.94rem, 3.11rem);
  --fs-xxl: clamp(1.98rem, 3.84vi + 1.07rem, 4.14rem);
  --fs-xxxl: clamp(2.48rem, 5.41vi + 1.19rem, 5.52rem);

  small,
  .small {
    font-size: var(--fs-sm);
  }
  p,
  .p {
    font-size: var(--fs-base);
  }
  h5,
  .h5 {
    font-size: var(--fs-md);
  }
  h4,
  .h4 {
    font-size: var(--fs-lg);
  }
  h3,
  .h3 {
    font-size: var(--fs-xl);
  }
  h2,
  .h2 {
    font-size: var(--fs-xxl);
  }
  h1,
  .h1 {
    font-size: var(--fs-xxxl);
  }
  a {
    color: #fff;
    font-size: var(--fs-base);

    &:hover {
      color: var(--color-secondary);
      text-decoration: none;
    }
  }
  input,
  select {
    height: auto;
  }
  /** TEXTS */

  .btn {
    border-radius: 2em;
    padding: 0.5em 2em;
    font-size: var(--fs-md) !important;
  }

  .header {
    background-image: url("/imgs/landing_0524/header_01.png");
    min-height: 75vh;
    border-bottom-left-radius: 3em;
    border-bottom-right-radius: 3em;
    padding-bottom: 3em;
    position: relative;
    color: white;

    nav {
      padding: 1em 0 2em;
    }

    h1 {
      font-size: var(--fs-xxl);
      font-weight: 600;
      line-height: 1.2;
    }
    h3 {
      font-weight: 500;
    }
  }

  /** Reverse z-index for sections overlap */
  section {
    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        z-index: #{10 - $i};
      }
    }
  }
  section:not(.header) {
    margin-top: -3em;
    padding-top: 6em;
    padding-bottom: 3em;
    position: relative;

    &:not(:last-of-type) {
      border-bottom-left-radius: 3em;
      border-bottom-right-radius: 3em;
    }
    &:last-of-type {
      padding-top: 7em;
      padding-bottom: 5em;
    }
  }
  footer {
    padding-top: 4em;
    padding-bottom: 3em;
    position: relative;
    z-index: 10;

    &:before {
      content: "";
      position: absolute;
      background: var(--color-secondary);
      clip-path: polygon(100% 76%, 100% 100%, 73.1% 100%);
      inset: 0;

      @media (min-width: 768px) {
        clip-path: polygon(80% 0, 100% 0, 100% 100%, 60% 100%);
      }
    }

    .socials {
      display: inline-flex;
      gap: 0.5em;

      a {
        color: var(--color-dark) !important;
        margin: 0;
        padding: 0;
      }
    }
  }

  .icon-container {
    padding: 0 0.5em;
    img {
      display: block;
      margin: 0 auto;
      height: 92px;
      width: 92px;
    }
    h5 {
      border-top: 2px solid var(--color-dark);
      padding-top: 0.5em;
      margin-top: 0.5em;
      font-weight: 500;
    }
  }

  .testimonial {
    grid-template-columns: 1fr;
    text-align: center;

    .stars {
      justify-content: center;
    }
  }

  .pre-footer {
    p.h5 {
      line-height: 1.8;
    }
  }

  @media (min-width: 768px) {
    .header h1 {
      font-size: var(--fs-xl);
    }
    .header h3 {
      font-size: var(--fs-md);
    }
    .btn {
      font-size: var(--fs-base) !important;
    }
    .icon-container h5 {
      font-size: var(--fs-base) !important;
    }
    section:not(.header) {
      padding-top: 8em;
      padding-bottom: 5em;
    }
  }
}
</style>
